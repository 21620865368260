import * as Yup from "yup"
import {
  REQUIRED_MESSAGE,
  INVALID_EMAIL,
  INVALID_MOBILE_NUMBER,
  INVALID_NOMINATION_CODE,
} from "services/validations"

const patientEnrollmentSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  mobileNumber: Yup.string()
    .min(11, INVALID_MOBILE_NUMBER)
    .max(11, INVALID_MOBILE_NUMBER)
    .matches(/^09[0-9]*$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
  emailAddress: Yup.string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_MESSAGE),
  hospitalClinic: Yup.object().shape({
    value: Yup.string().required(REQUIRED_MESSAGE),
  }),
  otherHospital: Yup.string().when("hospitalClinic", {
    is: (name) => name?.value === "Other",
    then: Yup.string().required("This field is required"),
  }), 
  prescribingDoctor: Yup.string().required(REQUIRED_MESSAGE),
  nominationCode: Yup.string()
    .matches(/^[A-z0-9]*$/, INVALID_NOMINATION_CODE)
    .required(REQUIRED_MESSAGE),
  deliveryAddress: Yup.object().shape({
    addressType: Yup.string().required(REQUIRED_MESSAGE),
    streetAddress: Yup.string().required(REQUIRED_MESSAGE),
    clinicHours: Yup.string().when("addressType", {
      is: "Hospital",
      then: Yup.string().required(REQUIRED_MESSAGE),
    }),
    province: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
    city: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
    barangay: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
  }),
  secondaryContactFirstName: Yup.string().required(REQUIRED_MESSAGE),
  secondaryContactLastName: Yup.string().required(REQUIRED_MESSAGE),
  secondaryContactMobileNumber: Yup.string()
    .min(11, INVALID_MOBILE_NUMBER)
    .max(11, INVALID_MOBILE_NUMBER)
    .matches(/^09[0-9]*$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
})

const doctorEnrollmentSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  mobileNumber: Yup.string()
    .min(11, INVALID_MOBILE_NUMBER)
    .max(11, INVALID_MOBILE_NUMBER)
    .matches(/^09[0-9]*$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
  emailAddress: Yup.string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_MESSAGE),
  prcNumber: Yup.string().required(REQUIRED_MESSAGE),
  specialization: Yup.object().shape({
    value: Yup.string()
      .required("This field is required")
      .nullable(),
  }),
  otherSpecialization: Yup.string().when("specialization", {
    is: specialization => specialization?.value === "Other (please specify)",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  patientFirstName: Yup.string().required(REQUIRED_MESSAGE),
  patientLastName: Yup.string().required(REQUIRED_MESSAGE),
  patientMobileNumber: Yup.string()
    .min(11, INVALID_MOBILE_NUMBER)
    .max(11, INVALID_MOBILE_NUMBER)
    .matches(/^09[0-9]*$/, INVALID_MOBILE_NUMBER)
    .required(REQUIRED_MESSAGE),
  patientAlreadyPrescribed: Yup.array().min(1, REQUIRED_MESSAGE),
})

export const getValidationSchema = module => {
  if (module === "patient") return patientEnrollmentSchema
  return doctorEnrollmentSchema
}

export const acknowledgementValidation = Yup.object().shape({
  hasAcknowledge: Yup.array().min(1, REQUIRED_MESSAGE),
})
