import React, { useEffect, useContext } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "../Layout/Layout"
import Message from "elements/Message"
import Section from "elements/Section"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"
import UploadGuidelines from "elements/UploadGuidelines"
import UploadPrescription from "elements/UploadPrescription"
import RequirementsMessage from "elements/Dropzone/RequirementsMessage"

import FormCheckbox from "elements/Form/FormCheckbox"

import { AppContext } from "../../context/AppContext"
import { generateFormField } from "elements/Form/services/form"
import { getValidationSchema } from "./utils/enrollmentFormValidationSchema"

import {
  GASTBY_ENCRYPTED_KEY,
  GATSBY_ENCRYPTED_VALUE,
} from "gatsby-env-variables"

const EnrollmentMechanics = props => {
  const { state, dispatch } = useContext(AppContext)
  const { documents } = state

  const {
    module,
    nextPath,
    backPath,
    basePath,
    enrollmentForm,
    prescriptionRequirement,
  } = props.pageContext
  const nextLink = module?.sku ? `/${module.sku}/${module.name}/${nextPath}` : `/${module.name}/${nextPath}`

  const getInitialValues = module => {
    if (module === "patient") return state?.patientEnrollment
    else return state?.patientNomination
  }

  let prescriptionBlurb =
    "I certify that I prescribed Tiotropium Bromide + Olodaterol HCl (Spiolto® Respimat®) and would like my patient to receive a single Spiolto® Respimat® refill pack to initiate their treatment."

  useEffect(() => {
    if (module.name === "doctor") {
      navigate(basePath)
    }
  }, [module])

  useEffect(() => {
    if (
      sessionStorage.getItem(GASTBY_ENCRYPTED_KEY) !== GATSBY_ENCRYPTED_VALUE
    ) {
      navigate(backPath)
    }
  }, [backPath])

  const handleFormSubmit = values => {
    if (module.name === "doctor") {
      dispatch({
        type: "SAVE_DOCTOR_ENROLLMENT",
        payload: { ...values },
      })
    } else {
      dispatch({ type: "SAVE_PATIENT_ENROLLMENT", payload: { ...values } })
    }
    navigate(nextLink)
  }

  return (
    <Layout
      title={module?.title}
      subtitle={module?.subtitle}
      seoTitle={`${module.seoTitle}`}
    >
      <Container isCentered>
        <Formik
          initialValues={getInitialValues(module.name)}
          validationSchema={getValidationSchema(module.name)}
          onSubmit={handleFormSubmit}
        >
          {({ values, setFieldValue, isValid, submitCount }) => (
            <Form>
              {enrollmentForm.map(section => (
                <Section
                  title={section?.section}
                  id={section?.id}
                  subtitle={section?.subtitle}
                  isRequired
                >
                  {section?.fields.map(field => {
                    if (!field?.referenceAnswer) {
                      field = { ...field, isNationwide: module?.isNationwide, sku: module?.sku }
                      return generateFormField({
                        formFields: section?.fields,
                        formField: field,
                        values,
                        setFieldValue,
                      })
                    }
                    return null
                  })}
                </Section>
              ))}
              {module.name === "doctor" && (
                <div className="mt-3">
                  <FormCheckbox
                    name="patientAlreadyPrescribed"
                    values={values.patientAlreadyPrescribed}
                    options={[prescriptionBlurb]}
                    setFieldValue={setFieldValue}
                  />
                </div>
              )}
              {/* {module.name === "doctor" && (
                <Section
                  title="Prescription"
                  id="prescription-information"
                  isRequired
                >
                  <RequirementsMessage
                    requirements={prescriptionRequirement}
                    header="Upload a valid prescription for Tiotropium Bromide + Olodaterol HCl (Spiolto® Respimat®). To be considered valid, the prescription must include:"
                  />
                  <UploadGuidelines />
                  <UploadPrescription label="Upload Prescription" maxFile={2} />
                  {submitCount > 0 && state.documents.length < 1 && (
                    <p className="help mt-0 mb-1 is-danger">
                      Please upload a prescription to proceed.
                    </p>
                  )}
                </Section>
              )} */}
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}
              <ActionButtons
                back={{ label: "Back", link: backPath }}
                submit={{
                  label: "Next",
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default EnrollmentMechanics
